.rkdtable {
  width: 100%;
  .ant-table {
    border-radius: 8px;
    .ant-table-content {
      border-radius: 8px;
      box-shadow: 0px 0px 6px 1px var(--color-shadow);
      overflow: hidden;
    }
    .ant-table-thead {
      .ant-table-cell {
        font-weight: var(--font-weight-semi-bold);
        font-size: 18px;
        padding-bottom: 2px;
        border-bottom: 2px solid var(--color-primary);
      }
    }
    .ant-table-tbody {
      .ant-table-row:nth-child(odd) {
        background-color: var(--color-grey) ;
      }
      .ant-table-cell {
        color: var(--color-secondary);
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item-link,
    .ant-pagination-item {
      border: none;
      color: var(--color-secondary);
      background: inherit;
    }
    .ant-pagination-item-link {
      font-size: 12px;
      &:hover,
      &:focus {
        color: var(--color-primary-darker);
      }
      &[disabled] {
        opacity: 0.4;
      }
    }
    .ant-pagination-item {
      font-size: 14px;
      a {
        &:hover,
        &:focus {
          color: var(--color-primary-darker);
        }
      }
      &.ant-pagination-item-active {
        border: none;
        a {
          color: var(--color-secondary);
          font-weight: var(--font-weight-semi-bold);
        }
      }
    }
  }
}
