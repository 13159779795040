.rkdbutton {
  font-weight: var(--font-weight-semi-bold);
  border: none;
  border-radius: 8px;
  .anticon {
    font-size: 18px;
  }
  &.ant-btn-sm .anticon {
    font-size: 15px;
  }
  &.ant-btn-lg .anticon {
    font-size: 22px;
  }
  &.rkdbutton-default {
    color: var(--color-white);
    background-color: var(--color-secondary);
    &:hover, &:focus {
      color: var(--color-primary);
    }
    &:active {
      color: var(--color-primary-darker);
    }
  }
  &.rkdbutton-primary {
    color: var(--color-secondary);
    background-color: var(--color-primary);
    &:hover, &:focus {
      background-color: var(--color-primary-darker);
    }
  }
  &.rkdbutton-secondary {
    color: var(--color-secondary);
    background-color: var(--color-white);
    border: 1px solid var(--color-secondary);
    &:hover, &:focus {
      color: var(--color-secondary-lighter);
    }
  }
  &.rkdbutton-tertiary,
  &.rkdbutton-tertiary-light {
    padding-right: 0px;
    padding-left: 0px;
    box-shadow: none;
    color: var(--color-secondary);
    background-color: transparent;
    span {
      font-weight: var(--font-weight-regular);
      text-decoration-line: underline;
    }
    &:hover {
      color: var(--color-secondary-lighter);
    }
    &[ant-click-animating-without-extra-node]:after {
        animation: none;
    }
  }
  &.rkdbutton-tertiary-light {
    color: var(--color-white);
    &:hover {
      color: var(--color-primary);
    }
  }
}
