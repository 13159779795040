.rkdcard {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--color-white);
  box-shadow: 0 0 8px 1px var(--color-shadow);
  .rkdcard-title {
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    color: var(--color-secondary);
    font-weight: var(--font-weight-semi-bold);
    font-size: 18px;
    line-height: 22px;
    .rkdcard-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 15px;
      background-color: var(--color-primary);
    }
  }
  .rkdcard-body {
    color: var(--color-secondary);
    font-size: 16px;
    line-height: 22px;
    white-space: pre-line;
  }
}
