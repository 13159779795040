.upload-modal {
  .upload-modal-title {
    font-size: 24px;
    font-weight: var(--font-weight-bold);
    color: var(--color-secondary);
    margin-top: 10px;
  }
  .upload-modal-subtitle {
    font-size: 14px;
    color: var(--color-secondary);
    margin-bottom: 20px;
  }
  .upload-modal-btns {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .upload-modal-btns-right {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .upload-modal-file {
    background-color: var(--color-grey);
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-content: center;
    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
  .upload-modal-uploading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .upload-modal-title {
      margin-bottom: 15px;
    }
    .upload-modal-spin {
      color: var(--color-secondary);
      font-size: 32px;
    }
  }
}
