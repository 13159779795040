@import 'styles/_section.scss';

.login-layout {
  @include section;
  padding: 0;
  background-color: var(--color-secondary);
  .login-header {
    flex: 1;
    .login-header-logo {
      height: 40px;
    }
  }
  .login-content {
    flex: 4;
    .login-content-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.login-reset-error {
  text-decoration: underline;
  &:hover, &:focus {
    text-decoration: underline;
    color: var(--color-secondary-lighter);
  }
}
