.resetpass-modal-title {
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  color: var(--color-secondary);
  margin-top: 10px;
}
.resetpass-modal-subtitle {
  font-size: 14px;
  color: var(--color-secondary);
  margin-bottom: 20px;
}
.resetpass-form {
  .ant-form-item-explain {
    font-size: 12px;
  }
  .resetpass-form-input {
    padding: 5px 10px;
    box-shadow: 0px 0px 10px 1px var(--color-shadow);
    border-radius: 8px;
  }
  .resetpass-form-button {
    margin-top: 15px;
  }
}
