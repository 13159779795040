
.predict-robot {
  height: 325px;
  max-width: 650px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .predicting-text {
    font-size: 18px;
  }
  .predict-result-text {
    text-align: center;
    font-size: 24px;
    line-height: 29px;
    font-weight: var(--font-weight-bold);
    margin-bottom: 5px;
    &.predict-result-success {
      color: var(--color-secondary);
    }
    &.predict-result-error {
      color: var(--color-error);
    }
  }
  .predict-result-detail {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-secondary);
    margin-bottom: 12px;
    span {
      font-weight: var(--font-weight-bold);
    }
  }
}
