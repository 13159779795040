.rkdalert {
  background-color: var(--color-white);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
  border: none;
  .ant-alert-icon {
    background-color: transparent;
    font-size: 50px;
    padding: 25px;
  }
  .ant-alert-close-icon,
  .ant-alert-close-icon .anticon-close {
    font-size: 12px;
    color: var(--color-secondary);
  }
  .ant-alert-message {
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    color: var(--color-secondary);
  }
  .ant-alert-description {
    font-size: 14px;
    color: var(--color-secondary);
  }
}
