:root {
  --color-primary: #FFFF00;
  --color-primary-darker: #D2D900;
  --color-secondary: #000000;
  --color-secondary-lighter: #6A6A6A;
  --color-white: #FFFFFF;
  --color-grey: #F3F3F3;
  --color-error: #DE0000;
  --color-shadow: rgba(149, 149, 149, 0.25);
  --font-primary: 'Inter', sans-serif;
  $weights: (
    // thin: 100,
    // extra-light: 200,
    // light: 300,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    bold: 700,
    extra-bold: 800,
    // black: 900,
  );
  @each $name, $weight in $weights {
    --font-weight-#{$name}: #{$weight};
  }
}
