@import 'styles/_section.scss';

.dashboard-layout {
  @include section;
  padding: 0;
  overflow: hidden;
  .dashboard-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2%;
    padding-bottom: 15px;
    .title {
      margin-bottom: 0;
    }
    .dashboard-data {
      text-align: right;
      color: var(--color-secondary);
      font-size: 14px;
      .dashboard-data-title {
        font-weight: var(--font-weight-bold);
      }
    }
  }
  .dashboard-loading {
    color: var(--color-secondary);
    font-size: 32px;
  }
  .dashboard-graphics-container {
    flex: 1;
  }
  .dashboard-no-graphics-container {
    flex: 1;
    margin-top: -10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-graphics-robot {
      margin-bottom: 2%;
    }
    .no-graphics-description {
      width: 55%;
      text-align: center;
      border-radius: 8px;
      box-shadow: 0px 0px 6px 1px var(--color-shadow);
      padding: 24px 18px;
      .no-graphics-title {
        color: var(--color-secondary);
        font-weight: var(--font-weight-semi-bold);
        font-size: 18px;
      }
      .no-graphics-text {
        font-size: 14px;
        padding: 6px 18px;
        margin-bottom: 12px;
      }
    }
  }
}
