.sidermenu {
  margin-top: 5%;
  .sidermenu-section {
    .ant-menu-item-group-title {
      font-size: 18px;
      color: var(--color-primary);
    }
  }
  .sidermenu-item {
    border-left: 7px solid transparent;
    font-size: 20px;
    &.ant-menu-item.ant-menu-item-selected {
      background-color: transparent;
      border-left: 7px solid var(--color-primary);
    }
    .sidermenu-icon {
      font-size: 24px;
    }
  }
}
