@import 'styles/_section.scss';

.perfil-layout {
  @include section;
  .perfil-data{
    background-color: var(--color-white);
    box-shadow: 0px 0px 6px 1px var(--color-shadow);
    border-radius: 8px;
    min-height: 100px;
    padding: 24px;
    font-size: 16px;
    span {
      display: inline-block;
      font-size: 18px;
      font-weight: var(--font-weight-semi-bold);
      margin-right: 15px;
    }
    .perfil-data-footer {
      margin-top: 30px;
      font-size: 14px;
      div {
        font-weight: var(--font-weight-semi-bold);
      }
    }
  }
  .perfil-logout-button {
    margin-top: 20px;
    margin-left: 15px;
  }
}
