@import 'styles/_section.scss';

.datos-layout {
  @include section;
  .datos-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    .title {
      margin-bottom: 0;
    }
  }
  .subtitle {
    font-weight: var(--font-weight-semi-bold);
    font-size: 18px;
    margin-bottom: 12px;
  }
  .datos-alert {
    width: 70%;
    margin-bottom: 36px;
    .datos-alert-bold {
      font-weight: var(--font-weight-semi-bold);
    }
  }
  .datos-records-empty-title {
    color: var(--color-secondary);
    font-weight: var(--font-weight-semi-bold);
    font-size: 18px;
  }
  .datos-records-empty-text {
    color: var(--color-secondary);
    font-size: 14px;
    padding: 12px 18px;
    margin-top: 12px;
  }
}
