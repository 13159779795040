.login-form {
  width: 100%;
  max-width: 500px;
  .login-form-header {
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 12px;
    .login-form-title {
      flex: 1;
      font-size: 24px;
      line-height: 0.8em;
      color: var(--color-white);
      font-weight: var(--font-weight-bold);
    }
  }
  .login-form-button {
    margin-top: 25px;
  }
  .login-form-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ant-input, .ant-input-affix-wrapper {
    border-radius: 8px;
    padding: 10px;
  }
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper {
    border-width: 2px;
  }
  div[role="alert"] {
    color: var(--color-white);
  }
}
