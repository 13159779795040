@import 'styles/_section.scss';

.predecir-layout {
  @include section;
  .title {
    margin-bottom: 15px;
  }
  .subtitle {
    font-size: 24px;
    font-weight: var(--font-weight-medium);
  }
  .predict-records {
    margin-top: 30px;
    .predict-records-title {
      font-weight: var(--font-weight-semi-bold);
      font-size: 18px;
      margin-bottom: 12px;
    }
    .predict-records-empty-title {
      color: var(--color-secondary);
      font-weight: var(--font-weight-semi-bold);
      font-size: 18px;
    }
    .predict-records-empty-text {
      color: var(--color-secondary);
      font-size: 14px;
      padding: 12px 18px;
      margin-top: 12px;
    }
  }
}
