@import 'styles/_section.scss';

.algoritmo-layout {
  @include section;
  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    margin-bottom: 15px;
    & > *:not(:last-child) {
      margin-right: 15px;
    }
  }
}
