.rkdmodal {
  .ant-modal-close-x {
    width: 32px;
    height: 32px;
    line-height: 32px;
    .anticon {
        color: var(--color-secondary);
        font-size: 12px;
    }
  }
  .ant-modal-header {
    border-radius: inherit;
    padding: 7px;
    border: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    .ant-modal-body {
      padding: 32px;
    }
  }
}
