@mixin section {
  background-color: var(--color-white);
  height: 100vh;
  overflow: auto;
  padding: 2%;
  padding-right: 8%;
  .title {
    font-weight: var(--font-weight-extra-bold);
    color: var(--color-secondary);
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 35px;
  }
}
